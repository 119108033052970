import VideoCamera from "@/components/VideoCamera/index";

import VideoService from '@/services/video.record.service';

const videoService = new VideoService();

export default {
  name: 'videos',
  components: {VideoCamera},
  props: [],
  data() {
    return {
      recording: null,
      showVideoModal: false,
      deleteVideoModal: {
        show: false,
        video: null,
      },
      videoForm: {
        audience: 'PERSONAL',
        title: '',
        description: ''
      },
      videos: [],
      totalRows: 0
    };
  },
  computed: {},
  mounted() {
    this.list();
  },
  methods: {
    async save() {
      const formData = new FormData();

      formData.append('Audience', this.videoForm.audience);
      formData.append('Title', this.videoForm.title);
      formData.append('Description', this.videoForm.description);
      formData.append('Recording', this.recording);


      const res = await videoService.create(formData);

      if(res && !res.error) {
        this.showVideoModal = false;
        this.$notify({
          type: 'success',
          message: this.$t('video_created')
        });

        await this.list();
      }

    },

    async deleteVideo() {
      const res = await videoService.remove(this.deleteVideoModal.video);
      if(res && !res.error) {
        this.deleteVideoModal = false;
        this.$notify({
          type: 'success',
          message: this.$t('video_deleted')
        });

        await this.list();
      }

    },

    async list() {
      const res = await videoService.list();
      if(res && !res.error) {
        this.videos = res.data.videos;
        this.totalRows = res.data.count;
      }
    },

    deleteModal(video) {
      this.deleteVideoModal.show = true;
      this.deleteVideoModal.video = video;
    },

    cancelDeleteModal() {
      this.deleteVideoModal.show = false;
      this.deleteVideoModal.video = null;
    },

    videoURL(video) {
      return this.$s3_url() + 'videos/' + video;
    },

    cancelRecord() {
      this.showVideoModal = false;
    },

    recordEnd(recording) {
      this.recording = recording;
    },

    onAudienceChange(audience) {
      this.videoForm.audience = audience;
    },

    onTitleChange(title) {
      this.videoForm.title = title;
    },

    onCTAChange(cta) {
      this.videoForm.cta = cta;
    },

    onDescriptionChange(description) {
      this.videoForm.description = description;
    }

  }
};


