import MailTemplateService from '@/services/mail.template.service';
import ColorPicker from "@/components/ColorPicker/index.vue";
import Validations from "./Validations";
import Errors from "./Errors";
import dh from '@/helpers/date.helper';

const mailTemplateService = new MailTemplateService();

export default {
    name: 'MailTemplateIndex',
    components: {ColorPicker},
    props: [],
    data() {
        return {
            createModal: false,
            categories: [],
            category: {
                Name: '',
                Color: ''
            }
        }
    },
    computed: {
        ...Errors
    },
    validations: Validations,
    async mounted() {
        await this.getCategories();
    },

    methods: {
        async getCategories() {
            const res = await mailTemplateService.listCategories();
            if (res && !res.error) {
                this.categories = res.data;
            }
        },
        async saveCategory() {
            await this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const res = await mailTemplateService.createCategory(this.category);
            if (res && !res.error) {
                await this.getCategories();
                this.category = {
                    Name: '',
                    Color: ''
                }
                this.createModal = false;
                this.$notify({
                    type: 'success',
                    message: this.$t('created_success'),
                    duration:4 * 1000,
                });
            } else {
                this.$notify({
                    type: 'error',
                    message: this.$t('created_error'),
                    duration:4 * 1000,
                });
            }
        },
        async removeCategory(id) {
            const res = await mailTemplateService.deleteCategory(id);
            if (res && !res.error) {
                await this.getCategories();
                this.$notify({
                    type: 'success',
                    message: this.$t('removed_success'),
                    duration:4 * 1000,
                });
            } else {
                this.$notify({
                    type: 'error',
                    message: this.$t('removed_error'),
                    duration:4 * 1000,
                });
            }
        },
        prettyDate(date) {
            return dh.customFormat(date, 'MM/DD/YYYY')
        }
    },

}
