import AudioRecorder from '@/components/AudioRecorder/index';
import AudioPlayer from '@/components/AudioPlayer/index';
import FileUploader from '@/components/FileUploader/index';
import AudioRecordService from '@/services/audio.record.service';

const service = new AudioRecordService();


export default {
    name: 'VoicemailTemplates',
    components: { AudioRecorder, FileUploader, AudioPlayer },
    props: [],
    data() {
        return {
            recordType: 'record',
            records: [],
            newAudioRecord: false,
            deleteModal: false,
            deleteRecord: null,
            recording: {
                Name: '',
                Record: null,
                Type: ''
            },

            perPage: 10,
            totalRows: 0,
            currentPage: 1,
        }
    },
    computed: {
        formValid() {
            return this.recording.Name.length > 0 && this.recording.Record && this.recording.Record !== 'null' && this.recording.Type.length > 0;
        }
    },
    mounted() {
        this.list();
    },
    methods: {
        async list(page) {
            this.currentPage = page || 1;
            const skip = this.perPage * (this.currentPage - 1);

            const res = await service.list(this.perPage, skip);
            if (res && !res.error) {
                this.records = res.data.records;
                this.totalRows = res.data.count;
            }
        },

        showRemove(record) {
            this.deleteRecord = record;
            this.deleteModal = true;
        },

        afterRecording(obj) {
            this.recording.Record = obj.blob;
            this.recording.Type = this.recordType;
        },

        afterUpload(obj) {
            this.recording.Record = obj;
            this.recording.Type = this.recordType;
        },

        async create() {
            const $data = new FormData();
            $data.append('name', this.recording.Name);
            $data.append('file', this.recording.Record);
            $data.append('type', this.recording.type);

            const res = await service.create($data);

            if ( res && !res.error ) {
                this.records.unshift(res.data);
                this.newAudioRecord = false;
            }

        },

        getRecordingUrl(record) {
            return this.$calls_server() + '/api/files/' + record.File+ '?token=' + localStorage.getItem('token');
        },
    }
}
