export default {
    name: 'file-uploader',
    components: {},
    props: {
        accepts:{type: String, enum: ['image', 'audio', 'video', 'all'], default: 'all'},
        afterUpload: {type: Function}
    },
    data() {
        return {
            loadedFile: null,
        }
    },
    computed: {
        getAcceptType() {
            const types = {
                image: '.jpg,.jpeg,.png',
                audio: '.mp3,.wav,.ogg',
                video: '.mp4,.avi',
                all: '*/*'
            };

            return types[this.accepts];
        }
    },
    mounted() {

    },
    methods: {
        handleUpload(e) {
            if (e.target.files.length > 0 ) {
                this.loadedFile = e.target.files[0];

                this.$refs.audioControl.src ="";
                this.$refs.audioControl.controls = false;
                this.$refs.audioControl.hidden = true;
                this.$refs.videoControl.src = "";
                this.$refs.videoControl.controls = false;
                this.$refs.videoControl.hidden = true;
                this.$refs.imageControl.src="";

                if ( this.accepts === 'image' || this.accepts === 'audio' || this.accepts === 'video' ) {
                    this.$refs[`${this.accepts}Control`].src = URL.createObjectURL(this.loadedFile);
                    this.$refs[`${this.accepts}Control`].controls = true;
                    this.$refs[`${this.accepts}Control`].hidden = false;
                }

                this.afterUpload && this.afterUpload(this.loadedFile);
            }
        }
    }
}
