export default {
    categoryNameErrors() {
        if (!this.$v.$anyError) return null;
        if (!this.$v.category.Name.required) return this.$t('required');
        return null;
    },
    categoryColorErrors() {
        if (!this.$v.$anyError) return null;
        if (!this.$v.category.Color.required) return this.$t('required');
        return null;
    }
}